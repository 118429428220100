/* eslint-disable */
const localization = {
    // This is the array of lexemes found in the app
    lexemes: [
      "Scarica Bumble",
      "Accetta tutti",
      "I cookie, tra cui quelli analitici e pubblicitari, ci servono a far funzionare meglio il sito. Leggi la nostra <a p0>Normativa sui cookie</a> per saperne di più.",
      "Gestisci opzioni",
      "Acconsenti all'utilizzo dei cookie?",
      "Leggi tutto",
      "Indietro",
      "Numero di telefono errato",
      "© {{current_year}} Bumble | Tutti i diritti sono riservati",
      "Categorie",
      "Articoli recenti",
      "Bumble Buzz",
      "Il nostro blog ha cambiato sito web. Dai un'occhiata per scoprire consigli su incontri, amicizie, benessere e molto altro: <a href=\"{{external_bumble_buzz_url}}\">bumble-buzz.com</a>",
      "Nuova casa per il nostro blog!",
      "Lavora con Bumble",
      "Non vendere né condividere le mie informazioni personali",
      "Segui @{{account_name}}",
      "Carriera",
      "Amicizia",
      "Amore",
      "Lasciaci la tua storia",
      "Storie a lieto fine",
      "Benessere",
      "Bumble BFF",
      "Bumble Bizz",
      "Bumble",
      "Lavora con noi",
      "Chi siamo",
      "Eventi",
      "Il momento è QUESTO per conoscere tanta gente interessante.",
      "Segui Bumble",
      "Linee Guida",
      "Assistenza",
      "Preferenze cookie",
      "Informativa sulla privacy",
      "L'app",
      "Termini & Condizioni",
      "Chi siamo",
      "Ambasciatori",
      "Blog",
      "Shop",
      "Scarica la App",
      "Scarica l'app per Android",
      "Scarica l'app per iOS",
      "Siamo spiacenti, ma la pagina che cerchi non è stata trovata.",
      "Accedi",
      "Invia",
      "Che fai, non vieni?",
      "Mostra tutti",
      "↓ Continua...",
      "Ops… qualcosa è andato storto"
],

    // These are the common words found in the lexemes of the app
    common: {
      "day": {
            "plural": [
                  "giorni"
            ],
            "singular": [
                  "giorno"
            ]
      },
      "hour": {
            "plural": [
                  "ore"
            ],
            "singular": [
                  "ora"
            ]
      },
      "minute": {
            "plural": [
                  "minuti"
            ],
            "singular": [
                  "minuto"
            ]
      },
      "month": {
            "plural": [
                  "mesi"
            ],
            "singular": [
                  "mese"
            ]
      },
      "second": {
            "plural": [
                  "secondi"
            ],
            "singular": [
                  "secondo"
            ]
      },
      "year": {
            "plural": [
                  "anni"
            ],
            "singular": [
                  "anno"
            ]
      }
}
};

// In dev mode we extend lexemes to include the indexMap as well, in production it remains as an array
if (__DEV__) {
    // This is a map of lexeme ids to their index in the array, it's only needed during development
    const indexMap = require('./index-map').default;

    localization.indexMap = indexMap;

    Object.entries(localization.indexMap).forEach(([indexKey, indexValue]) => {
        localization.lexemes[indexKey] = localization.lexemes[indexValue];
    });
}

export default localization;
